
import { Component, Vue } from 'vue-property-decorator'

import {
  mdiCheckboxMarked,
  mdiCheckboxBlankOutline,
  mdiEye,
  mdiEyeOff
} from '@mdi/js'
import UniversalCookie from 'universal-cookie'
import SecondaryTitle from '~/components/shared/SecondaryTitle.vue'
import infomationAlert from '~/components/shared/infomationAlert.vue'
import OtherLogin from '~/components/shared/OtherLogin.vue'
import MixinValidationRules from '~/components/mixins/MixinVuetifyRules'
import MixinKarteTracking from '~/components/mixins/MixinKarte'
import MixinMeta from '~/components/mixins/MixinMeta'
import authSetting from '~/config/authSetting'
import { Meta } from '~/types/meta'
import { API } from '~/types/api'

const camelcaseKeys = require('camelcase-keys')

@Component({
  components: {
    SecondaryTitle,
    infomationAlert,
    OtherLogin
  },
  mixins: [MixinKarteTracking, MixinMeta, MixinValidationRules]
})
export default class login extends Vue {
  private get headBase(): Meta.HeadBase {
    return {
      title: 'ログイン｜talentbook(タレントブック)',
      link: [
        {
          rel: 'canonical',
          href: process.env.BASE_URL + this.$route.path
        }
      ]
    }
  }

  private isValid: boolean = false
  private email: string = ''
  private password: string = ''
  private showPass: boolean = false
  private loginError: boolean = false
  private isChecked: boolean = false
  private labelText: string = 'ログイン状態を保持する'
  private loggedoutText: string = `ログアウトしました、再度ログインする場合は下記より行ってください。`
  private links = require('~/assets/json/links.json')
  private checkboxOnIcon = mdiCheckboxMarked
  private checkboxOffIcon = mdiCheckboxBlankOutline
  private mdiEye = mdiEye
  private mdiEyeOff = mdiEyeOff

  private get alerts() {
    return [
      {
        display: this.loginError,
        classname: 'isError',
        text: this.errorText,
        block: true
      },
      {
        display: this.loginError,
        classname: ['isSuccess', 'spnone'],
        text: this.cmsGuideText,
        block: true
      },
      {
        display: this.loggedOut,
        classname: 'isSuccess',
        text: this.loggedoutText,
        block: true
      }
    ]
  }

  private get errorText() {
    return `入力された内容でログインできませんでした。\n内容をご確認の上、再度お試しください。`
  }

  private get cmsGuideText() {
    return `<a href="${this.links.cmsLogin}" class="annotation-text-link" target="_blank">企業担当者様はこちらからログインをお願いします。</a>`
  }

  private mounted(): void {
    const cookie = new UniversalCookie()
    cookie.set('before_login', true)
  }

  private async login(): Promise<void> {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      await this.$axios
        .$get(csrfUrl, {
          auth: authSetting,
          withCredentials: true
        })
        .then((token: API.CSRF) => {
          this.loginAction(token.csrf_token)
        })
    } catch (e) {
      this.loginError = true
      return e
    }
  }

  private async loginAction(csrf: string): Promise<void> {
    const cookies = new UniversalCookie()
    const url = process.env.API_URL + '/members/auth'
    const postData: any = {
      email: this.email,
      password: this.password
    }
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    if (this.isChecked) postData.remember_me = '1'
    try {
      await this.$axios
        .$post(url, postData, config)
        .then((rs: any) => {
          const response = camelcaseKeys(rs, { deep: true })
          sessionStorage.setItem(
            'member',
            JSON.stringify({ id: response.memberId })
          )
          cookies.set('email_login', true)
        })
        .then(() => {
          // API401の際にsetされるcookie削除
          cookies.remove('unauthorized_error')
          // 退会完了時にsetされるcookie削除
          cookies.remove('quitMessage')
          const redirectUrl = `/members/mypage`
          // login messageをlayoutのmoutedに配置しているためlocation.hrefでリダイレクト
          window.location.href = redirectUrl
        })
    } catch (err) {
      this.loginError = true
      return err
    }
  }

  private get loggedOut(): boolean {
    return !!this.$route.query.logout && !this.loginError
  }
}
